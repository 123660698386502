* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/* TABLE */
/*table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

td {
  font-family: Helvetica;
  font-size: 18px;
}

th, td {
  padding: 2px;
}

tr:hover {
  background-color: #f5f5f5
}*/
table {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  height: 100%;
  text-align: left;
  border-collapse: collapse;
}
table td, table th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table tbody td {
  font-size: 14px;
}
table tr:nth-child(even) {
  background: #D0E4F5;
}
table thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}
table thead th {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
table thead th:first-child {
  border-left: none;
}

table tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}
table tfoot td {
  font-size: 14px;
}
table tfoot .links {
  text-align: right;
}
table tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

.error {
  color: red
}

/*tbody tr:nth-child(odd) {
   background-color: #ccc;
}
*/
body {
   /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;*/
   font-family: roboto, helvetica, arial, sans-serif;
   font-weight: 300;
   font-size:  14px;
}

input[type=text], input[type=password], textarea {
  box-sizing: border-box;
  border: 1px solid #66c;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
}

select {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 1px solid #66f;
  border-radius: 4px;
  color: #555;
  font-size: inherit;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

button, input[type=button], input[type=submit], input[type=reset] {
  border: none;
  border-radius: 4px;
  background-color: #66f;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  font-size:  12px;
}

.search-box {
  width: 100%;
  height: 80px;
}

.search-button {
  width: 100px;
}

.explanation {
  width: 300px;
}

.top-nav {
  margin: 10px;
}

.container {
  display: flex;
  width: 100%;
}

.left-nav {
  width: 150px;
}

.dashboard {
  flex: 1;
}

.fixedpanel {
  position: fixed;
  z-index: 100;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 1px solid #ccc;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  background-color: #fff;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 15px;
}

/************* FORM **************/
form {
  /*width: 600px;*/
  padding: 1em;
  border-radius: 1em;
}

form ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

form li + li {
  margin-top: 1em;
}

label {
  /* Uniform size & alignment */
  display: inline-block;
  width: 90px;
  text-align: right;
}

input,
textarea {
  /* To make sure that all text fields have the same font settings
     By default, textareas have a monospace font */
  font: 1em sans-serif;

  /* Uniform text field size */
  width: 300px;
  box-sizing: border-box;

  /* Match form field borders */
  border: 1px solid #999;
}

input:focus,
textarea:focus {
  /* Additional highlight for focused elements */
  border-color: #000;
}

textarea {
  /* Align multiline text fields with their labels */
  vertical-align: top;

  /* Provide space to type some text */
  height: 5em;
}

.button {
  /* Align buttons with the text fields */
  padding-left: 90px; /* same size as the label elements */
}

button {
  /* This extra margin represent roughly the same space as the space
     between the labels and their text fields */
  margin-left: .5em;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.dashboard-category {
  color: #333333;
  margin-top: 10px;
  /*text-decoration: underline;*/
}

.dashboard-item {
  /*border: 1px solid #ddd;*/
  height:  100%;
  overflow: auto;
}

.dashboard-item-title {
  font-size: 20px;
  font-weight: bold;
}

.link-button {
  color: blue;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-box {
  color: blue;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.quote-text {
  font-size: 14px;
}

.quote-other {
  font-size: 12px;
}

.dynamic-form {
  width: 100%;
}

.dynamic-form-row {
  display: table-row;
  /*width: 100%;*/
}

.dynamic-form-label {
  display: table-cell;
  padding: 10px;
}

.dynamic-form-input {
  display: table-cell;
  width: 100%;
}

.dynamic-form-widget {
  width: 100%;
}
